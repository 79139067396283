'use client'

import React from 'react'
import ReactDOM from 'react-dom'
import { NextPageContext } from 'next'
import { Language, PageByUrlQuery, PageCommonQuery, SeoQuery } from 'generated/sdk'
import { QueryName, seoFetcher, pageByUrlFetcher, pageCommonFetcher, validateQueryParams } from 'utils/url'
import { ErrorPage, StatusCodes } from 'components/error-page/error-page'
import useSWR from 'swr'
import { LoadingPage } from 'components/loading-page/loading-page'
import { baseLayoutDataMapper, getRenditionUrl, getSlideThumbnail } from 'utils/data'
import { BaseLayout } from 'components/layouts/base-layout/base-layout'
import dynamic from 'next/dynamic'
import { notFound } from 'next/navigation'

const loading = () => <LoadingPage />

const Homepage = dynamic(() => import('components/homepage/homepage'), { loading })
const NewsDetailPage = dynamic(() => import('components/news-detail-page/news-detail-page'), { loading })
const CategoryPage = dynamic(() => import('components/category-page/category-page'), { loading })
const PressReleasePage = dynamic(() => import('components/press-release-page/press-release-page'), {
	loading,
})
const SearchPage = dynamic(() => import('components/search-page/search-page'), { loading })
const EventDetailPage = dynamic(() => import('components/event-detail-page/event-detail-page'), { loading })
const EventsPage = dynamic(() => import('components/events-page/events-page'), { loading })
const StandardPage = dynamic(() => import('components/standard-page/standard-page'), { loading })
const TrafficPage = dynamic(() => import('components/traffic-page/traffic-page'), { loading })

interface Props {
	lang: Language
	searchParams: { [key: string]: string | undefined }
	params: { slug: string[] }
	urlPath: string
	seoInitialData: {
		data: SeoQuery | null
		error?: NextPageContext['err']
	}
	commonInitialData: {
		data: PageCommonQuery | null
		error?: NextPageContext['err']
	}
	pageInitialData: {
		data: PageByUrlQuery | null
		error?: NextPageContext['err']
	}
}

// TODO - Tentar passar estas componentes para SSR?
// TODO - Quando opageLoading é true => Passar o Loading state nos layouts das páginas em vez de aqui (pág de eventos)

const seoQuery: QueryName = 'Seo'
const commonQuery: QueryName = 'PageCommon'
const pageQuery: QueryName = 'PageByUrl'

const DataWrapper = ({
	seoInitialData,
	commonInitialData,
	pageInitialData,
	lang,
	urlPath,
	searchParams,
	params,
}: Props) => {
	const slug = params?.slug
	const { startDate, searchQuery, before, page } = validateQueryParams(searchParams)
	let statusCode: StatusCodes | null = null

	if (!Object.values(Language).includes(lang)) {
		statusCode = 404
	}

	const {
		data: seoData,
		error: seoError,
		isLoading: seoLoading,
	} = useSWR([seoQuery, lang], seoFetcher, {
		fallbackData: seoInitialData.data !== null ? seoInitialData.data : undefined,
		dedupingInterval: 0,
	})
	const {
		data: commonData,
		error: commonError,
		isLoading: commonLoading,
	} = useSWR([commonQuery, lang], pageCommonFetcher, {
		fallbackData: commonInitialData.data !== null ? commonInitialData.data : undefined,
		dedupingInterval: 0,
	})
	const {
		data: pageData,
		error: pageError,
		isLoading: pageIsLoading,
	} = useSWR([pageQuery, urlPath, startDate, searchQuery, page, before], pageByUrlFetcher, {
		fallbackData: pageInitialData.data !== null ? pageInitialData.data : undefined,
		dedupingInterval: 0,
	})

	if (statusCode === 404) {
		notFound()
	} else if (statusCode && statusCode !== 404) {
		return <ErrorPage statusCode={statusCode} />
	}

	if (commonError || pageError || seoError) {
		return <ErrorPage statusCode={500} error={commonError || pageError || seoError} />
	}

	if (!commonData || !seoData || commonLoading || seoLoading) {
		return <LoadingPage isFullScreen />
	}

	let pageComponent: JSX.Element

	if (!pageData || pageIsLoading) {
		pageComponent = <LoadingPage />
	} else if (pageData.pageByUrl === null || pageData.pageByUrl === undefined) {
		notFound()
	} else {
		const pageType = pageData.pageByUrl.__typename

		//* This preloads the necessary images on client side without adding the <link rel="preload" /> tag
		if (pageType === 'Article' || pageType === 'PhotoGallery' || pageType === 'Video' || pageType === 'Event') {
			ReactDOM.preload(getRenditionUrl(pageData.pageByUrl.thumbnail.large), {
				as: 'image',
				fetchPriority: 'high',
				imageSizes: '100vw',
			})

			if (pageType === 'Article' || pageType === 'PhotoGallery') {
				pageData.pageByUrl.sliderItems.forEach(item => {
					const seoThumbnail = getSlideThumbnail(item.image, false, true)

					ReactDOM.preload(seoThumbnail.src, {
						as: 'image',
						fetchPriority: 'high',
						imageSizes: '100vw',
					})
				})
			}
		} else {
			if (seoData.seo?.sharableImage.rendition) {
				ReactDOM.preload(seoData.seo.sharableImage.rendition.url, {
					as: 'image',
					fetchPriority: 'high',
					imageSizes: '100vw',
				})
			}
		}

		const searchParamsObj = {
			before,
			startDate,
			searchQuery,
			page: Number(page),
			slug,
		}
		switch (pageData.pageByUrl.__typename) {
			case 'HomeIndex':
				pageComponent = <Homepage pageData={pageData.pageByUrl} />
				break

			case 'Article':
			case 'Video':
			case 'PhotoGallery':
				pageComponent = <NewsDetailPage pageData={pageData.pageByUrl} />
				break

			case 'ArticlesCategoryIndexPage':
			case 'ArticlesSubCategoryIndexPage':
			case 'ArticlesHighlightsIndexPage':
			case 'ArticlesIndexPage':
			case 'PhotoGalleriesCategoryIndexPage':
			case 'PhotoGalleriesSubCategoryIndexPage':
			case 'PhotoGalleriesHighlightsIndexPage':
			case 'PhotoGalleriesIndexPage':
			case 'VideosCategoryIndexPage':
			case 'VideosSubCategoryIndexPage':
			case 'VideosHighlightsIndexPage':
			case 'VideosIndexPage':
			case 'TopicIndexPage':
				pageComponent = <CategoryPage searchParams={searchParamsObj} pageData={pageData.pageByUrl} />
				break

			case 'Event':
				pageComponent = <EventDetailPage pageData={pageData.pageByUrl} />
				break

			case 'EventsIndexPage':
			case 'EventsCategoryIndexPage':
				pageComponent = <EventsPage searchParams={searchParamsObj} pageData={pageData.pageByUrl} />
				break

			case 'PressRelease':
				pageComponent = <PressReleasePage pageData={pageData.pageByUrl} />
				break

			case 'SearchIndexPage':
			case 'SearchArticlesIndexPage':
			case 'SearchVideosIndexPage':
			case 'SearchPhotoGalleriesIndexPage':
				pageComponent = <SearchPage searchParams={searchParamsObj} pageData={pageData.pageByUrl} />
				break

			case 'StandardPage':
				pageComponent = <StandardPage pageData={pageData.pageByUrl} />
				break

			case 'TrafficPage':
				pageComponent = <TrafficPage pageData={pageData.pageByUrl} />
				break

			default:
				pageComponent = <ErrorPage statusCode={404} isFullScreen={false} />
		}
	}

	const { headerProps, footerProps } = baseLayoutDataMapper(commonData, lang)

	return (
		<BaseLayout headerProps={headerProps} footerProps={footerProps}>
			{pageComponent}
		</BaseLayout>
	)
}

export default DataWrapper
